import { Injectable } from "@angular/core";
import { ApiAiClient } from "api-ai-javascript/es6/ApiAiClient";
import { RichMessage } from "../models/rich-message";
import { Subject, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, count } from "rxjs/operators";
import { Chat } from "../models/chats";
import { JsonPipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class DialogflowService {
  public session: any;
  public chat: Chat;
  token: string; //acces token del bot de dialogflow lucy v2
  client: any;
  chatSubject = new Subject<RichMessage[]>(); //sera escuchado por todos los componentes que lo requieran
  sessionSubject = new Subject<String[]>();
  conversation: RichMessage[] = [];
  failedMessageCounter = 0; //contador de mensajes fallidos 
  bSuperPreload: boolean = false;
  public possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  public lengthOfCode = 30;
  public sessionCode;
  //SERVER
  public uriCoordenadas = 'https://chatbotvolty.edeq.com.co/chatWeb/chatbotEDEQPruebas/coordenadas.php';
  public url = 'https://chatbotvolty.edeq.com.co/api';

  //USER_CHABOT
  //public uriCoordenadas = 'https://chatbotchecserver.com/chatbotEDEQUsuarios/coordenadas.php';
  //public url = 'https://chatbotchecserver.com:4443/back-edeq';
  //public url = 'https://chatbotvolty.edeq.com.co/api';
  //add_header 'Access-Control-Allow-Origin' '*';

  constructor(private http: HttpClient) {
    this.chat = new Chat("");
  }

  connectToApi() {
    //this.client = new ApiAiClient({ accessToken: this.token }); //instancia de DialogFlow
    this.sentToBot({ text: "start", sentBy: "human" });
  }

  sentToBot(messageObject) {
    this.publishMessage(new RichMessage(messageObject));
    this.postToDialofFlow(messageObject.text);
  }

  sentToHuman(messageObject) {
    this.publishMessage(new RichMessage(messageObject));
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  //Función que hace la peticion a dialogflow con los mensajes
  public df_client_call(request, mensajeParaEnviar): Observable<any> {
    let config = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json; charset=utf-8",
      },
    };

    if (mensajeParaEnviar === "start") {
      this.sessionCode = this.makeRandom(this.lengthOfCode, this.possible);
    }
    /*return this.http.post(
      "https://dialogflow.googleapis.com/v2/projects/" +
        "edeq-produccion-v-es-9q9i" +
        "/agent/sessions/" +
        this.sessionCode +
        ":detectIntent",
      request,
      config
    );*/
    return this.http.post(
      "https://dialogflow.googleapis.com/v2/projects/" +
        "edeq-desarrollo-v-es-bkod" +
        "/agent/sessions/" +
        this.sessionCode +
        ":detectIntent",
      request,
      config
    );
  }

  postToDialofFlow(mensajeParaEnviar: string) {
    //peticion de mensaje a dialogflow v2 - retorna respuesta
    let request = {
      queryInput: {
        text: {
          text: mensajeParaEnviar,
          languageCode: "es-CO",
        },
      },
    };

    this.df_client_call(request, mensajeParaEnviar).subscribe(
      (respuesta) => {
        if (respuesta.queryResult.intent.displayName == "14reportarFallaEnergiaCuentaAsociada" || respuesta.queryResult.intent.displayName == "14indisponibilidadCuentaAsociada" || respuesta.queryResult.intent.displayName == "14reportarFallaObserNomCelDocAuthReporte" || respuesta.queryResult.intent.displayName == "tramite_vinculacion - niu" || respuesta.queryResult.intent.displayName == "c12_re_niuCPagoActual" || respuesta.hasOwnProperty('webhookStatus') == false || respuesta.webhookStatus.code != 4) {
          if (respuesta.queryResult.fulfillmentMessages) {
            let msg = respuesta.queryResult.fulfillmentMessages; //capturando la respuesta(payload) que viene de dialogflow
            //let sessionId = respuesta.responseId;
            let sessionId = this.sessionCode;
            this.sessionSubject.next(sessionId);
            let flag = true; //controlar que tipo de mensaje se va a mostrar en el chat(desde consola de dialogflow o fulfillment)
            let payloadChatWebLucy = ""; //capturar el payload del fulfillment
            msg.forEach((values, key) => {
              var payload = values.payload;
              if (payload) {
                //validar que la respuesta de dialogflow tenga payload
                if (payload.chatWebLucy) {
                  flag = false;
                  payloadChatWebLucy = payload.chatWebLucy;
                  this.messageWithPayload(payloadChatWebLucy);
                }
              }
            });
            if (flag) {
              let msg = respuesta.queryResult.fulfillmentText; // capturamos la respuesta
              this.messageWithOutPayload(msg);
            }
          }
        } else {
          if (this.failedMessageCounter <= 5) {
            this.failedMessageCounter = this.failedMessageCounter + 1;
            this.postToDialofFlow(mensajeParaEnviar);
          } else {
            this.failedMessageCounter = 0;
          }
        }
      },
      (err) => {
        //En caso de que el token se haya vencido, se vuelve a solicitar uno nuevo
        if (err.error.error.status === "UNAUTHENTICATED") {
          this.getToken().subscribe(
            (res) => {
              this.token = res.token;
              this.postToDialofFlow(mensajeParaEnviar);
            },
            (err) => {
              //console.log(err)
            }
          );
        }
      }
    );
  }

  postToDialofFlow2(mensajeParaEnviar: string) {
    //peticion de mensaje a dialogflow v1- retorna respuesta
    this.client.textRequest(mensajeParaEnviar).then((respuesta) => {
      //hacer una peticion a dialogflow
      if (
        respuesta.result.fulfillment.messages ||
        respuesta.result.fulfillment.speech
      ) {
        let msg = respuesta.result.fulfillment.messages; //capturando la respuesta(payload) que viene de dialogflow
        let sessionId = respuesta.sessionId;
        this.sessionSubject.next(sessionId);
        //console.log(msg);
        let flag = true; //controlar que tipo de mensaje se va a mostrar en el chat(desde consola de dialogflow o fulfillment)
        let payloadChatWebLucy = ""; //capturar el payload del fulfillment
        msg.forEach((values, key) => {
          var payload = values.payload;
          if (payload) {
            //validar que la respuesta de dialogflow tenga payload
            if (payload.chatWebLucy) {
              flag = false;
              payloadChatWebLucy = payload.chatWebLucy;
              this.messageWithPayload(payloadChatWebLucy);
              //parent.postMessage("GetWhiteLabel", "*");
            }
          }
        });
        if (flag) {
          let msg = respuesta.result.fulfillment.speech; // capturamos la respuesta
          this.messageWithOutPayload(msg);
        }
      }
    });
  }

  getToken(): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.url}/getToken/`, { headers: headers });
  }

  //para los mensajes que vienen de la consola de dialog flow
  messageWithOutPayload(msg) {
    //PASAR COMO PARAMETRO LA SESSIONID
    msg = msg.split("&");
    msg.forEach((value, key) => {
      try {
        msg = value.replace(/\(/g, "{").replace(/\)/g, "}");
        //msg = value.replace(/{a}/gi, '(a)');
        msg = JSON.parse(`${msg}`); //toma una cadena JSON y la transforma en un objeto JavaScript
        //console.log(msg);
        msg.sentBy = "Volty";
        msg = new RichMessage(msg); //instancia del modelo de las tarjetas(RichMessage)
      } catch (error) {
        msg = new RichMessage({
          text: msg,
          sentBy: "Volty",
        });
      }
      //console.log(msg);
      //setTimeout(() => {
      this.publishMessage(msg); //PASAR COMO PARAMETRO LA SESSIONID
      // }, 1500)
    });
  }

  //para los mensaje que vienen del fulfillment
  messageWithPayload(msg) {
    //PASAR COMO PARAMETRO LA SESSIONID
    try {
      //console.log(msg);
      msg.sentBy = "Volty";
      msg = new RichMessage(msg); //instancia del modelo de las tarjetas(RichMessage)
    } catch (error) {
      msg = new RichMessage({
        text: msg,
        sentBy: "Volty",
      });
    }
    //console.log(msg);
    //setTimeout(() => {
    this.publishMessage(msg); //PASAR COMO PARAMETRO LA SESSIONID
    // }, 1500)
  }

  //almacena el mensaje que se va a mostrar en el chat
  publishMessage(rm: RichMessage) {
    if (rm.text != "start") {
      this.conversation.push(rm); //Se almacena el mensaje en un array tipo RichMessage
      this.chatSubject.next(this.conversation); //Agregar un array al subject
    }
  }

  //almacena el mensaje en la base de datod
  saveToDb(chat): Observable<any> {
    let params = JSON.stringify(chat); //https://backchat.herokuapp.com/
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.url}/saveChats`, params, {
      headers: headers,
    });
    //return this.http.post('https://chatwebback.herokuapp.com/saveChats', params, { headers: headers });
  }

  //almacena la session en la base de datos
  saveSession(session): Observable<any> {
    let params = JSON.stringify(session);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.url}/saveSessionId`, params, {
      headers: headers,
    });
  }

  //obtener la session en la base de datos
  getSession(session): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.url}/getSessionId/` + session, {
      headers: headers,
    });
  }

  //obtener la sessiones en la base de datos
  getSessions(): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(`${this.url}/getSessions`, { headers: headers });
  }

  //almacenar interaccion de puntos de atención
  saveInteractionPointsOfAttention(interaction): Observable<any> {
    let params = JSON.stringify(interaction);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    //console.log(params);
    return this.http.post(`${this.url}/saveInteraction`, params, {
      headers: headers,
    });
  }

  //log de las veces que alguien usa la url
  saveLogUrlLucy(logUrlLucy): Observable<any> {
    let body = JSON.stringify(logUrlLucy);
    //console.log(body);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.url}/saveLogUrl`, body, { headers: headers });
  }

  saveRespondeAuth(response): Observable<any> {
    let params = JSON.stringify(response);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(`${this.url}/saveResponseAuth`, params, {
      headers: headers,
    });
  }

  //Reemplaza toda la conversacion existente por un array vacio
  setConversation(con: RichMessage[]) {
    this.chatSubject.next(con);
  }

  //limpiar la conversacion del chat
  clear() {
    this.conversation = [];
    this.setConversation(this.conversation);
    this.sentToBot({ text: "hola", sentBy: "human" });
    // this.init();
  }

  makeCapitalMarkers(distance, coordinates): Observable<any> {
    let coordenadas = JSON.stringify({
      coordenadas: coordinates,
      distancia: distance,
    });
    return this.http.post(`${this.uriCoordenadas}`, coordenadas);
  }

  //Guardar coordenadas
  saveCoordinates(coordinates, codUser, distance) {
    let coordenadas = JSON.stringify({
      coordenadas: coordinates,
      codUser: codUser,
      distance: distance,
    });
    return this.http.post(`${this.uriCoordenadas}`, coordenadas);
  }

  preloaderOn(bSuperPreload?: boolean) {
    if (bSuperPreload) {
      this.bSuperPreload = bSuperPreload;
    }
    if (document.querySelector("#preloader")) {
      document.querySelector("#preloader")!.classList.remove("hide_preloader");
      document.querySelector("#preloader")!.classList.add("show_preloader");
    } else {
      var d1 = document.querySelector("body");
      //d1!.insertAdjacentHTML('beforeend', '<div  id="preloader"><div class="showbox"><div class="loader1"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg><div class="cargando">Cargando...</div></div></div><div class="preloader view" style="height:100vh; width:100vw; position:fixed; top:0; left:0; z-index:9999 !important; opacity:0.5; background:black;"><div class="flex-center"></div></div></div>');
      d1!.insertAdjacentHTML(
        "beforeend",
        `<div  id="preloader" class="position-fixed" style=" z-index:99999999999999999999999;">
          <div class="position-fixed backdrop_preload w-100 flex-center">
             <div class="avatar_container">
                <div  class="avatar2 sombra avatar_preload  animate__animated animate__bounce animate__infinite" style="width: 70px; height: 70px;">
                  <img class="logo_circulo" src="./assets/images/edeq.png">
                </div>
                  <div class="text-white texto_cargando mt-3">
                    Cargando...
              </div>
          </div>
        </div>`
      );
    }
  }

  preloaderOff(bSuperPreload?: boolean) {
    if (bSuperPreload) {
      this.bSuperPreload = false;
    }
    if (!this.bSuperPreload) {
      if (document.querySelector("#preloader")) {
        document
          .querySelector("#preloader")!
          .classList.remove("show_preloader");
        document.querySelector("#preloader")!.classList.add("hide_preloader");
      }
    }
  }
}
