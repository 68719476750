<div class="dashboard-content-inner">

    <div class="messages-container margin-top-0">

        <div class="messages-container-inner">


            <!-- Message Content -->
            <app-chat-window></app-chat-window>
            <!-- Message Content -->
            
        </div>
    </div>
</div>