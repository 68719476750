<div *ngIf="message.type!='text'" [ngClass]="{'me' : message.sentBy === 'human'}" class="message-bubble">
    <div class="message-bubble-inner">
        <div *ngIf="isInfo"  class="message-text">

            <youtube-player *ngIf="message.videoUrl"
               videoId={{idVideo}}
               [height]="250"
               [width]="'100%'"
               suggestedQuality="highres">
            </youtube-player>

            <div id="contImg">
                <div *ngIf="message.imageUrl&&imagenDescarga" #ovalo id="ovalo" (click)="downloadPdf(message.docUrl)"></div>
                <img *ngIf="message.imageUrl&&imagenDescarga" #descarga [src]="cargaYdescarga" id='descarga' (click)="downloadPdf(message.docUrl)">
                <img *ngIf="message.imageUrl" [src]="message.imageUrl" (click)="zoomImage()" class="imgweb">
            </div>

            <p *ngIf="activarUrlDespedida"> {{message.text}} <a style="color: #026c72" href="https://www.facebook.com/EDEQGrupoEPM/" target="__blank"> <i class="fab fa-facebook-square fa-lg"></i> </a> <a style="color: #026c72" href="https://www.instagram.com/edeqgrupoepm/" target="__blank"> <i class="fab fa-instagram  fa-lg"></i> </a> <a style="color: #026c72" href="https://twitter.com/EDEQGrupoEPM" target="__blank"> <i class="fab fa-twitter  fa-lg"></i> </a> <a style="color: #026c72" href="https://www.youtube.com/user/EdeqSaEsp" target="__blank"> <i class="fab fa-youtube  fa-lg"></i> </a></p>
            <p *ngIf="mensajeCuenta" > Este es el número de cuenta para este predio: {{message.text}}</p>
            <p *ngIf="!mensajeCuenta && !activarUrlDespedida" [innerHTML]="message.text">{{message.text}}<br>
                <span *ngIf="!message.chips" id="horaMe">{{today | date:'shortTime'}}</span>
            </p>
            <!-- <div *ngIf="mapa" id="map"></div> -->            
            <p *ngIf="message.webUrl"><small><a target="__blank" [href]="message.webUrl">Click aqui para abrir este link</a></small></p>
            
            <div *ngIf="message.chips && message.chips.length">
                <span (click)="chipOperation(c)" *ngFor="let c of message.chips" class="chip shadow">{{c.text}}</span>
                <span id="hora">{{today | date:'shortTime'}}</span>
            </div>
            <p *ngIf="activarUrl" class="message-policity"> <a href="https://www.edeq.com.co/acerca-de-edeq/quienes-somos/proteccion-de-datos-personales" target="__blank">Política de protección de datos personales</a></p>
        </div>
        <div *ngIf="!isInfo" class="message-text message-text-info">
            <p>{{firstMessage}} <br></p>
          </div>
          <div *ngFor="let msm of selectedArray" class="message-text message-text-info">
            <p>👉{{msm}}</p>
          </div>
          <div *ngIf="showChips" class="message-text">
            <div *ngIf="message.chips && message.chips.length && showChips">
              <span role="button" tabindex="0" (keydown.enter)="chipOperation(c)" (click)="chipOperation(c)" *ngFor="let c of message.chips" class="chip">{{c.text}}</span>
              <span id="hora">{{today | date:'shortTime'}}</span>
            </div>
          </div>

    </div>
    <div class="clearfix"></div>
</div>

<div *ngIf="false" class="message-bubble" style="border: 1px solid red">
    <div class="message-bubble-inner">
        <div class="message-avatar"><img [src]="message.botImg" alt=""></div>
        <div class="message-text shadow">
            <!-- Typing Indicator -->
            <div class="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>

