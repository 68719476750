<div class="message-content card shadow-lg" [hidden]="loadingData">

    <div class="messages-headline">

        <div class="icon-dedeq shadow-lg">
            <div class="circle-online">
            </div>
            <img class="img-edeq" src="./assets/images/avatar2.png">
            <div class="circle-online">
            </div>
            <!--
            <img class="shadow icon-edeq" id="iconoLucy" src="./assets/images/edeq.png" alt="">
            -->
        </div>

        <h1 class="text-center" id="nombreCabecera">EDEQ - Grupo EPM</h1>

        <a (click)="clearConversationp()" class="message-action">
            <i class="icon-feather-trash-2"></i>
            <!--
            <i class="fas fa-times"></i>
            <i class="fas fa-minus" ></i>
            -->
        </a>
    </div>

    <!-- Message Content Inner -->
    <div class="message-content-inner" id="message-content-inner">
        <app-message *ngFor="let m of conversation" [message]="m" (onChipSelected)="onChipSelected($event)">
        </app-message>
    </div>
    <!-- Message Content Inner / End -->

    <!-- Reply Area -->
    <div class="message-reply">
        <input type="text" placeholder="Envía un mensaje" [(ngModel)]="inputMessage" (keyup.enter)="sendMessage()">

        <div (click)="sendMessage()" class="mt-1  shadow" style="background-color: #026c72; border-radius: 50%; color: white; height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; align-self: center;">
            <i style="align-self: center; margin-bottom: 20px;" class="fas fa-paper-plane"></i>
        </div>
        <!--
        <i style="background-color: #009ab1de; border-radius: 50%; color: white; height: 40px; width: 40px;" class="fas fa-paper-plane fa-1.5x p-3 shadow" (click)="sendMessage()"></i>
        -->


        <!--<button (click)="sendMessage()" class="button ripple-effect" style="background-color: rgba(28, 192, 28, 0.658);">Enviar</button></i>-->
    </div>
    <!---
    <button style="background-color: #0069d9; color: white;" mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
        <mat-icon>arrow_upward</mat-icon>
    </button>--->

</div>
