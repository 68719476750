import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Chip } from 'src/app/models/rich-message';
import { DialogflowService } from 'src/app/services/dialogflow.service';
import Swal from 'sweetalert2';
import * as moment from 'moment'
import { onErrorResumeNextStatic } from 'rxjs/internal/operators/onErrorResumeNext';
//import * as L from 'leaflet';



// variable para que angular no muestre error, pues el metodo que usa viene un archivo js, en assets
declare const pdfToImg: any;

//filesaver es una libreria usada para descargar los pdf de otro servicio
declare var require: any;
const FileSaver = require('file-saver');
declare const L: any;
const iconRetinaUrl = 'assets/images/marker-icon-2x.png';
const iconUrl = 'assets/images/marker-icon.png';
const MyiconUrl = 'assets/images/marker-icon_red.png';
const shadowUrl = 'assets/images/marker-shadow.png';
const iconUrl_init = 'assets/images/marker-icon_green.png';
/*var LeafIcon = L.Icon.extend({
  options: {
    shadowUrl: shadowUrl,
    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [12, 41],
    shadowAnchor: [4, 62],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  },
});
let greenIcon = new LeafIcon({ iconUrl: iconUrl_init });
let redIcon = new LeafIcon({ iconUrl: MyiconUrl });*/

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {


  public today = new Date();//para mostrar las horas en el chat
  public imagenDescarga: boolean = false;//flag para controlar que los iconos de descarga solo salgan en los mensajes que vienen con pdf
  public cargaYdescarga = "./assets/images/web.png";//falg para cambiar de imagen(de descarga a carga)
  public mensajeCuenta = false;
  public distance: number = 300    // 5.042848, -75.478340, -75.522120, 5.071584
  //public mapa = false;
  public map
  public activarUrl: boolean = false
  public activarUrlDespedida: boolean = false
  public idVideo: any
  public firstMessage = ''
  public isInfo = true
  public interval
  public showChips = false
  public selectedArray = []

  @Input() message;
  @ViewChild('descarga') descarga; //controlar algunos atributos css
  @ViewChild('ovalo') ovalo; //controlar algunos atributos css
  constructor(private _dfs: DialogflowService) { }

  ngOnInit(): void {
    this.formatearTexto(this.message.text)
    this.formatearTextoSuspensione(this.message.text)

    //controal cuando se va a mostrar los iconos de descarga
    if (this.message.docUrl) {

      var name = this.message.docUrl.split(".");

      if (name[0] == "https://chatbotvolty" || name[0] == "https://adminedeq") {
        this.imagenDescarga = true;
      }
    }
    //this.limpiarMapa();
    //this.dispararMapa(this.message.text);

    /*if (this.message.text.length == 9) {
      var cadena = `${this.message.text}`;
      var result = /^[0-9]*$/.test(cadena);
      if (result) {
        this.mensajeCuenta = true;
      }
    }*/
    if (this.message.videoUrl) {
      let separarUrl = this.message.videoUrl.split('=')
      this.idVideo = separarUrl[separarUrl.length - 1]
      this.video()
    }

    if (this.message.text === '¡Hola! Soy Volty tu asesor virtual. ¿Cuéntame, cómo te puedo ayudar hoy?') {
      this.activarUrl = true
    } else {
      this.activarUrl = false
    }

    if (this.message.text === 'Gracias por contactarte con EDEQ. Recuerda que soy Volty y estoy aquí para ayudarte. Si te gustó la experiencia compártela con tus amigos.') {
      this.activarUrlDespedida = true
    } else {
      this.activarUrlDespedida = false
    }

    if (this.message.text.startsWith('{')) {
      //this.message.text = ':)'
      let textos = JSON.parse(this.message.text)
      this.firstMessage = textos['mensajeInicial']
      let existeFlagInfografia = textos.hasOwnProperty('isInfofactura')
      if (existeFlagInfografia) {
        this.isInfo = false
        const mensajes = textos.mensaje

        //const mensajesLimpios = mensajes.map(m => m.mensaje)
        //const extraerMensajes = mensajesLimpios.map(m => m[0])
        let index = 0
        this.interval = setInterval(() => {
          if (index == mensajes.length) {
            this.showChips = true
            document.getElementById('message-content-inner').scrollTop = 9999999;
            clearInterval(this.interval)
            return;
          }
          this.selectedArray.push(mensajes[index]);
          index++;
          document.getElementById('message-content-inner').scrollTop = 9999999;
        }, 2000);
      }
    }
  }



  video() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  formatearTexto(mensaje) {
    switch (mensaje) {
      case 'Para el municipio de salento conozco la siguiente información:  (1) Punto de servicio:  Plaza principal -> Calle 6 No. 6-18 y Horario de atención: Miércoles y viernes(7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 p.m.)': {
        this.message.text = '<b>Para el municipio de Salento conozco la siguiente información:</b>  <br/><b>Oficina Plaza principal:</b> Calle 6 No. 6-18 Miércoles y viernes en horario (7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 p.m.)'
        break
      }

      case 'Para el municipio de quimbaya conozco la siguiente información:  (1) Punto de servicio:  local 107 -> Cll 15 No 6 - 16 y Horario de atención: Lunes a Viernes(7:00 a.m. a 12:00 a.m. y 1:30 p.m. a 4:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Quimbaya conozco la siguiente información:</b>  <br/><b>Oficina local 107:</b> Cll 15 No 6 - 16 de Lunes a Viernes en horario (7:00 a.m. a 12:00 a.m. y 1:30 p.m. a 4:30 p.m.)'
        break
      }

      case 'Para el municipio de pijao conozco la siguiente información:  (1) Punto de servicio:  Cll 12 No 4 - 15 y Horario de atención: Miércoles y viernes(7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 p.m.)': {
        this.message.text = '<b>Para el municipio de Pijao conozco la siguiente información:</b>  <br/><b>Oficina:</b> Cll 12 No 4 - 15 Miércoles y viernes en horario (7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 p.m.)'
        break
      }

      case 'Para el municipio de la tebaida conozco la siguiente información:  (1) Punto de servicio:  local 1 -> Cra 5 No 12 - 14  y Horario de atención: Lunes a Viernes(7:00 a.m. a 12:00 a.m. y 1:30 p.m. a 4:30 p.m.)': {
        this.message.text = '<b>Para el municipio de la Tebaida conozco la siguiente información:</b>  <br/><b>Oficina local 1:</b> Cra 5 No 12 - 14  de Lunes a Viernes en horario (7:00 a.m. a 12:00 a.m. y 1:30 p.m. a 4:30 p.m.)'
        break
      }

      case 'Para el municipio de montenegro conozco la siguiente información:  (1) Punto de servicio:  Edif. Firenza local 2 -> Carrera 5 No 13 - 12 y Horario de atención: Lunes a Viernes(7:00 a.m. a 12:00 a.m. y 1:30 p.m. a 4:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Montenegro conozco la siguiente información:</b>  <br/><b>Oficina Edif. Firenza local 2:</b> Carrera 5 No 13 - 12 de Lunes a Viernes en horario(7:00 a.m. a 12:00 a.m. y 1:30 p.m. a 4:30 p.m.)'
        break
      }

      case 'Para el municipio de filandia conozco la siguiente información:  (1) Punto de servicio:  Plaza Principal -> Cll 7 No 5 - 34 y Horario de atención: Martes y jueves(7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) Sábado(8:00 a.m. a 12:00 p.m.)': {
        this.message.text = '<b>Para el municipio de Filandia conozco la siguiente información:</b>  <br/><b>Oficina Plaza Principal:</b> Cll 7 No 5 - 34 Martes y jueves en horario(7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) Sábado(8:00 a.m. a 12:00 p.m.)'
        break
      }

      case 'Para el municipio de circasia conozco la siguiente información:  (1) Punto de servicio:  Cra 14 6 - 51 piso 1 y Horario de atención: Lunes a Viernes(7:00 a.m. a 12:00 p.m. y 1:30 p.m. a 4:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Circasia conozco la siguiente información:</b>  <br/><b>Oficina piso 1:</b> Cra 14 6 - 51 de Lunes a Viernes en horario(7:00 a.m. a 12:00 p.m. y 1:30 p.m. a 4:30 p.m.)'
        break
      }

      case 'Para el municipio de calarca conozco la siguiente información:  (1) Punto de servicio:  Oficina central y archivo central - Punto servicio al cliente -> Cll 40 No 29 33 y Horario de atención: Lunes a Viernes(7:00 a.m. a 12:00 p.m. y 1:30 p.m. a 4:30 p.m.) (2) Punto de servicio:  Sede Recaudo - Punto servicio al cliente -> Cra 25 No 35 - 38 y Horario de atención: Lunes a Viernes(7:00 a.m. a 12:00 p.m. y 1:30 p.m. a 4:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Calarcá conozco la siguiente información:</b>  <br/><b>Oficina central y archivo central - Punto servicio al cliente:</b> Cll 40 No 29 33 de Lunes a Viernes en horario(7:00 a.m. a 12:00 p.m. y 1:30 p.m. a 4:30 p.m.) <br/><br>Oficina Sede Recaudo - Punto servicio al cliente:</br> Cra 25 No 35 - 38 de Lunes a Viernes en horario(7:00 a.m. a 12:00 p.m. y 1:30 p.m. a 4:30 p.m.)'
        break
      }

      case 'Para el municipio de buenavista conozco la siguiente información:  (1) Punto de servicio:  Cll 2 No 2 - 32 y Horario de atención: Miércoles(7:00 a.m. - 11:30 a.m. y 1:30 a.m - 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 m.)': {
        this.message.text = '<b>Para el municipio de Buenavista conozco la siguiente información:</b>  <br/><b>Oficina:</b> Cll 2 No 2 - 32 los Miércoles en horario (7:00 a.m. - 11:30 a.m. y 1:30 a.m - 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 m.)'
        break
      }

      case 'Para el municipio de barcelona conozco la siguiente información:  (1) Punto de servicio:  Cra 11 No 11 - 16 y Horario de atención: Miércoles y jueves(7:00 a.m. - 11:30 a.m. y 1:30 a.m - 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 m.)': {
        this.message.text = '<b>Para el municipio de Barcelona conozco la siguiente información:</b>  <br/><b>Oficina:</b>  Cra 11 No 11 - 16 los Miércoles y jueves en horario (7:00 a.m. - 11:30 a.m. y 1:30 a.m - 5:00 p.m.) - Sábado(8:00 a.m. - 12:00 m.)'
        break
      }

      case 'Para el municipio de armenia conozco la siguiente información:  (1) Punto de servicio:  Principal -> Cra 13 No 14 - 17 y Horario de atención: Lunes a Viernes(7:00 a.m. - 5:00 p.m. Jornada continua) - Sábado(Solo recaudo)(8:00 a.m. a 12:00 pm) (2) Punto de servicio:  Fundadores -> Cra 13ª norte 1ª - 35 y Horario de atención: Lunes a Viernes(Solo recaudo)(7:00 a.m. - 5:00 p.m. Jornada continua) - Sábado(8:00 a.m. a 12:00 pm)': {
        this.message.text = '<b>Para el municipio de Armenia conozco la siguiente información:</b>  <br/><b>Oficina Principal:</b> Cra 13 No 14 - 17 de Lunes a Viernes en horario (7:00 a.m. - 5:00 p.m. Jornada continua) - Sábado(Solo recaudo)(8:00 a.m. a 12:00 pm) <br/><b>Oficina Fundadores:</b> Cra 13ª norte 1ª - 35 de Lunes a Viernes(Solo recaudo) en horario(7:00 a.m. - 5:00 p.m. Jornada continua) - Sábado(8:00 a.m. a 12:00 pm)'
        break
      }

      case 'Para el municipio de armenia conozco la siguiente información:  (1) Punto de servicio:  Las Américas -> Calle 23 No. 33 - 15 local 13 y Horario de atención: Lunes a Viernes(Solo recaudo)(7:00 a.m. - 12:00 pm y 1:30 p.m. - 5:00 p.m) (2) Punto de servicio:  San Diego -> Cll 30 No 80 - 40 local 3 y Horario de atención: Lunes a Viernes(Solo recaudo)(7:00 a.m. - 12:00 pm y 1:30 p.m. - 5:00 p.m)': {
        this.message.text = '<b>Para el municipio de armenia conozco la siguiente información:</b>  <br/><b>Oficina Las Américas:</b> Calle 23 No. 33 - 15 local 13 de Lunes a Viernes en horario (Solo recaudo)(7:00 a.m. - 12:00 pm y 1:30 p.m. - 5:00 p.m) <br/><b>Oficina San Diego:</b> Cll 30 No 80 - 40 local 3 de Lunes a Viernes(Solo recaudo) en horario(7:00 a.m. - 12:00 pm y 1:30 p.m. - 5:00 p.m)'
        break
      }

      case 'Para el municipio de armenia conozco la siguiente información:  (1) Punto de servicio:  Sede principal - Centro de control -> Cra 13 No. 14-17 y Horario de atención: Sede administrativa(24 horas al día todos los días 6:30 a.m. - 6:30 p.m.) (2) Punto de servicio:  Sede principal - Ambiente de control -> Cra 13 No. 14-17 y Horario de atención: Lunes a Viernes(6:30 a.m. - 6:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Armenia conozco la siguiente información:</b>  <br/><b>Oficina Sede Principal - centro de control</b>: Cra 13 No. 14-17 Sede administrativa(24 horas al día todos los días 6:30 a.m. - 6:30 p.m.) <br/><b>Oficina Sede Principal - ambiente de control:</b> Cra 13 No. 14-17 de Lunes a Viernes en horario(6:30 a.m. - 6:30 p.m.)'
        break
      }

      case 'Para el municipio de armenia conozco la siguiente información:  (1) Punto de servicio:  Sede principal - Ambiente de control -> Cra 13 No. 14-17 y Horario de atención: Lunes a Viernes(6:30 a.m. - 6:30 p.m.) (2) Punto de servicio:  Sede principal - Tercer piso ambiente compartido -> Cra 13 No. 14-17 y Horario de atención: Lunes a Viernes(6:30 a.m. - 6:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Armenia conozco la siguiente información:</b>  <br/><b>Oficina Sede principal - Ambiente de control:</b> Cra 13 No. 14-17 de Lunes a Viernes en horario(6:30 a.m. - 6:30 p.m.) <br/><b>Oficina Sede principal - Tercer piso ambiente compartido:</b> Cra 13 No. 14-17 de Lunes a Viernes en horario(6:30 a.m. - 6:30 p.m.)'
        break
      }

      case 'Para el municipio de armenia conozco la siguiente información:  (1) Punto de servicio:  Sede Mantenimiento de Redes -> Carrera 21 No. 12-04 entrada vehículos - Calle 13 No. 20-34 entrada peatonal y Horario de atención: Lunes a Viernes - Sede operativa(24 horas al día todos los días 6:30 a.m. - 6:30 p.m.) (2) Punto de servicio:  Sede Administrativa Almacén -> Calle 50 Frente a Ciudadela Puerto Espejo y Horario de atención: Sede administrativa - Sede operativa(24 horas al día todos los días 6:30 a.m. - 6:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Armenia conozco la siguiente información:</b>  <br/><b>Oficina Sede Mantenimiento de Redes:</b> Carrera 21 No. 12-04 entrada vehículos - Calle 13 No. 20-34 entrada peatonal de Lunes a Viernes - Sede operativa en horario (24 horas al día todos los días 6:30 a.m. - 6:30 p.m.) <br/><b>Oficina Sede Administrativa Almacén:</b> Calle 50 Frente a Ciudadela Puerto Espejo Sede administrativa - Sede operativa en horario(24 horas al día todos los días 6:30 a.m. - 6:30 p.m.)'
        break
      }

      case 'Para el municipio de armenia conozco la siguiente información:  (1) Punto de servicio:  Sede Administrativa Cabaña - incluye Subestación -> Carrera 21 No. 12-03 y Horario de atención: Sede administrativa - Lunes a Viernes(6:30 a.m - 6:30 p.m.)': {
        this.message.text = '<b>Para el municipio de Armenia conozco la siguiente información:</b>  <br/><b>Oficina Sede Administrativa Cabaña - incluye Subestación:</b> Carrera 21 No. 12-03 Sede administrativa de Lunes a Viernes en horario(6:30 a.m - 6:30 p.m.)'
        break
      }
      case 'Para el municipio de genova conozco la siguiente información:  (1) Punto de servicio:  Cll 25 No 10 - 14 y Horario de atención: Martes y jueves(7:00 a.m. a 11:00 a.m. y 1:30 p.m. a 4:30 p.m.) Sábado(8:00 a.m. a 12:00 p.m.)': {
        this.message.text = '<b>Para el municipio de Genova conozco la siguiente información:</b>  <br/><b>Oficina</b> Cll 25 No 10 - 14 los Martes y jueves en horario (7:00 a.m. a 11:00 a.m. y 1:30 p.m. a 4:30 p.m.) Sábado(8:00 a.m. a 12:00 p.m.)'
        break
      }
      case 'Para el municipio de cordoba conozco la siguiente información:  (1) Punto de servicio:  Cra 14 6 - 51 piso 1 y Horario de atención: Martes y jueves(7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) Sábado(8:00 a.m. a 12:00 p.m.)': {
        this.message.text = '<b>Para el municipio de Cordoba conozco la siguiente información:</b>  <br/><b>Oficina Cra 14 6 - 51 piso 1:</b> los Martes y jueves en horario(7:00 a.m. a 11:30 a.m. y 1:30 p.m. a 5:00 p.m.) Sábado(8:00 a.m. a 12:00 p.m.)'
        break
      }
      case 'Hola, aquí te enseño cómo obtener la copia de tu factura de energía, por favor ingresa el año y el mes del cual quieres obtener tu duplicado de factura en el siguiente formato: año/número de mes  ejemplo: 2021/09': {
        this.message.text = 'Hola, aquí te enseño cómo obtener la copia de tu factura de energía:  <br/><b>Primero</b> debes ingresar el año y el mes del cual quieres obtener tu duplicado de factura en el siguiente formato: año/número de mes <br/><b>ejemplo:</b>  2021/09'
        break
      }
      case 'Una queja es una manifestación verbal y/o escrita, de inconformidad con respecto a la actuación o conducta de un funcionario de EDEQ, o insatisfacción con la forma y condiciones en que se ha prestado el servicio o se ha brindado algún tipo de información. Algunos tipos de queja son:  inconformidad en la atención o información suministrada por parte de un funcionario, Calidad del servicio o entrega inoportuna de la factura. Puedes radicar tu reclamo en nuestros puntos de atención.': {
        this.message.text = 'Una <b>queja</b> es una manifestación verbal y/o escrita, de inconformidad con respecto a la actuación o conducta de un funcionario de EDEQ, o insatisfacción con la forma y condiciones en que se ha prestado el servicio o se ha brindado algún tipo de información. Algunos <b>tipos de queja</b> son:  inconformidad en la atención o información suministrada por parte de un funcionario, Calidad del servicio o entrega inoportuna de la factura. Puedes radicar tu reclamo en nuestros puntos de atención.'
        break
      }
      case 'Una petición es la solicitud, manifestación o aspiración respetuosa de una persona en calidad de usuario, suscriptor o no del servicio público, dirigida a EDEQ con el fin de que la empresa le tramite y le de respuesta oportuna y de fondo a temas relacionados con la prestación del servicio. Algunos tipos de peticiones son:  Estado de factura, duplicado de factura, cambio de datos básicos o vinculación de un nuevo servicio.': {
        this.message.text = 'Una <b>petición</b>  es la solicitud, manifestación o aspiración respetuosa de una persona en calidad de usuario, suscriptor o no del servicio público, dirigida a EDEQ con el fin de que la empresa le tramite y le de respuesta oportuna y de fondo a temas relacionados con la prestación del servicio. Algunos <b>tipos de peticiones</b>  son:  Estado de factura, duplicado de factura, cambio de datos básicos o vinculación de un nuevo servicio.'
        break
      }
      case 'Un reclamo es Derecho que tiene toda persona de exigir, reivindicar o demandar una solución ya sea por motivo general o particular, referente a la  falta de atención de una solicitud  o relacionado con la prestación indebida de un servicio. Algunos tipos de reclamo son:  inconformidad con el consumo facturado, cobro de un concepto particular, cobros adicionales derivados de la conexión, reconexión o instalación del servicio. Puedes radicar tu reclamo en nuestros puntos de atención.': {
        this.message.text = 'Un <b>reclamo</b> es Derecho que tiene toda persona de exigir, reivindicar o demandar una solución ya sea por motivo general o particular, referente a la  falta de atención de una solicitud  o relacionado con la prestación indebida de un servicio. Algunos <b>tipos de reclamo</b> son:  inconformidad con el consumo facturado, cobro de un concepto particular, cobros adicionales derivados de la conexión, reconexión o instalación del servicio. Puedes radicar tu reclamo en nuestros puntos de atención.'
        break
      }
      case 'Si seleccionas el siguiente link podrás obtener el tipo de certificación que necesites, ya sea de constancia de matrícula, estado de cuenta o disponibilidad de servicio': {
        this.message.text = 'Si seleccionas el siguiente link podrás obtener el tipo de <b>certificación</b> que necesites, ya sea de constancia de matrícula, estado de cuenta o disponibilidad de servicio'
        break
      }
      case 'Gracias por contactarte con EDEQ. Recuerda que soy Volty y estoy aquí para ayudarte. Si te gustó la experiencia compártela con tus amigos.': {
        this.message.text = 'Gracias por contactarte con EDEQ. Recuerda que soy Volty y estoy aquí para ayudarte. <br/>Si te gustó la experiencia compártela con tus amigos.'
        break
      }
      case '🌎 Indícame el *nombre del municipio* a consultar, sin espacios, puntos ni caracteres extraños. Ejemplo: Armenia, Salento, etc.': {
        this.message.text = '🌎 Indícame el <b>nombre del municipio</b> a consultar, sin espacios, puntos ni caracteres extraños. Ejemplo: Armenia, Salento, etc.'
        break
      }
      case 'Aquí te enseño cómo obtener la copia de tu factura de energía, Primero debes ingresar el año y el mes del cual quieres obtener tu duplicado de factura en el siguiente formato: año/número de mes  Ejemplo: 2021/09': {
        this.message.text = 'Aquí te enseño cómo obtener la copia de tu factura de energía <br/><b>Primero</b> debes ingresar el año y el mes del cual quieres obtener tu duplicado de factura en el siguiente formato: año/número de mes  <b>Ejemplo</b>: 2021/09'
        break
      }
      case 'Para el nombre del titular de la cuenta y la dirección de residencia que me indicaste, encuentro que el número de cuenta es: *$cuenta*': {
        this.message.text = 'Para el nombre del titular de la cuenta y la dirección de residencia que me indicaste, encuentro que el número de cuenta es: <b>$cuenta</b>'
        break
      }
      case 'Ingresa por favor el número de cuenta EDEQ Código NIU, el cual puedes ubicar en la parte superior derecha de tu factura. 👉 Recuerda: Sin letras, puntos y espacios': {
        this.message.text = 'Ingresa por favor el número de cuenta EDEQ <b>Código NIU</b>, el cual puedes ubicar en la parte superior derecha de tu factura.<br/> 👉 <u>Recuerda: Sin letras, puntos y espacios</u>'
        break
      }
    }
  }

  formatearTextoSuspensione(mensaje) {
    if (mensaje != undefined) {
      const msg = mensaje.split(":")
      if (msg[0] === "Para tu derección") {
        this.message.text = mensaje.split("\n").join("<br/>");
      }
    }
  }


  prepararDatos(datos) {
    let data = datos.split("\n").join("<br />");
    //console.log('darta', data)
    return data;
  }

  chipOperation(c) {

    if (c.text == 'Pago en línea') {
      let d = new Date();
      let fecha = moment(d).format('YYYY-MM-DD h:mm:ss');
      let params = {
        interaction: 'f_pago_linea',
        fecha: fecha
      }
      //console.log(params);
      this._dfs.saveInteractionPointsOfAttention(params).subscribe(
        response => {
          //console.log(response);
        },
        error => {
          console.log(error);
        }
      );
    } else if (c.text == 'Radica aquí tu PQR') {
      let d = new Date();
      let fecha = moment(d).format('YYYY-MM-DD h:mm:ss');
      let params = {
        interaction: 'f_radicar_prq',
        fecha: fecha
      }
      //console.log(params);
      this._dfs.saveInteractionPointsOfAttention(params).subscribe(
        response => {
          //console.log(response);
        },
        error => {
          console.log(error);
        }
      );
    } else if (c.text == 'Conocer Certificaciones') {
      let d = new Date();
      let fecha = moment(d).format('YYYY-MM-DD h:mm:ss');
      let params = {
        interaction: 'f_certificaciones',
        fecha: fecha
      }
      //console.log(params);
      this._dfs.saveInteractionPointsOfAttention(params).subscribe(
        response => {
          //console.log(response);
        },
        error => {
          console.log(error);
        }
      );
    }

    if (c.hintMsg) {
      this._dfs.sentToBot({
        text: c.hintMsg,
        sentBy: 'human'
      })
    } else {
      window.open(c[c.type], '__blank');
    }
  }

  zoomImageInvoice() {
    //console.log('prueba factura');
  }

  //metodo para agrandar cierta imagen
  zoomImage() {

    var url = [
      //'https://chatbotchecserver.com/chatbotEDEQ/img/factura-creg.png',
      //'https://chatbotchecserver.com/chatbotEDEQ/img/peticion.png',
      //'https://chatbotchecserver.com/chatbotEDEQ/img/queja.png',
      //'https://chatbotchecserver.com/chatbotEDEQ/img/reclamo.png'
      'https://chatbotvolty.edeq.com.co/chatWeb/chatbotEDEQPruebas/img/factura-creg.png',
      'https://chatbotvolty.edeq.com.co/chatWeb/chatbotEDEQPruebas/img/peticion.png',
      'https://chatbotvolty.edeq.com.co/chatWeb/chatbotEDEQPruebas/img/queja.png',
      'https://chatbotvolty.edeq.com.co/chatWeb/chatbotEDEQPruebas/img/reclamo.png'
    ]

    if (url.indexOf(this.message.imageUrl) >= 0) {
      Swal.fire({
        // title: 'Sweet!',
        //text: 'Modal with a custom image.',
        imageUrl: this.message.imageUrl,
        imageHeight: 200,
        imageWidth: 450,
        imageAlt: 'Custom image',
        confirmButtonColor: '#99be00',
      })
    }
  }

  //descargar la factura y cupon de pago
  downloadPdf(pdfUrl: string) {


    Promise.resolve().then(_ => this.cargaYdescarga = "./assets/images/loader.gif");

    var name = pdfUrl.split(".");
    var doc = pdfUrl.split("/");
    var docFull = doc[doc.length - 1].split("=");
    var url = "";

    if (name[0] == "https://chatbotvolty") {
      var url = pdfUrl;
      let separateDir = pdfUrl.split(".com");
      //let url = '/var/www/html' + separateDir[1];
      var pdfName = 'cupon_' + doc[doc.length - 1];
      this.saveInvoiceCopy('cupon_pago')

    } else if (name[0] == "https://adminedeq") {
      //http://chatbotchecserver.com:8080/
      //https://dl-dev.herokuapp.com/
      //var url = 'https://dl-dev.herokuapp.com/' + pdfUrl;
      //var url = 'https://cors-anywhere.herokuapp.com/' + pdfUrl;
      var url = pdfUrl;
      var pdfName = 'copia_factura_' + docFull[docFull.length - 1] + '.pdf';
      this.saveInvoiceCopy('copia_factura')
    }
    //let url = 'https://cors-anywhere.herokuapp.com/' + pdfUrl;
    //https://cors-lucy.herokuapp.com/

    Promise.resolve().then(_ => FileSaver.saveAs(url, pdfName));
    Promise.resolve().then(_ => this.cargaYdescarga = "./assets/images/web.png");

    Promise.resolve().then(_ => this.chagePropertiesImages());
  }

  saveInvoiceCopy(typeInteraction) {
    let d = new Date();
    let fecha = moment(d).format('YYYY-MM-DD h:mm:ss');
    let params = {}
    if (typeInteraction == 'copia_factura') {
      params = {
        fecha: fecha,
        interaction: 'fCopia_factura_descarga'
      }
    } else {
      params = {
        fecha: fecha,
        interaction: 'fCupon_pago_descarga'
      }
    }
    this._dfs.saveInteractionPointsOfAttention(params).subscribe(
      res => {
        //console.log(res)
      },
      err => {
        console.log(onErrorResumeNextStatic)
      }
    )
  }

  //propiedades que seran cambiadas del css
  chagePropertiesImages() {
    this.descarga.nativeElement.style.top = "9px"
    this.descarga.nativeElement.style.cursor = "pointer"
    this.descarga.nativeElement.style.width = "44px"
    this.descarga.nativeElement.style.height = "34px"
    this.descarga.nativeElement.style.position = "absolute"
    this.descarga.nativeElement.style.left = "250px"
    this.descarga.nativeElement.style.borderRadius = "7px"
    this.descarga.nativeElement.style.backgroundColor = "#0707073d"
    this.ovalo.nativeElement.style.backgroundColor = "#07070700"
    this.ovalo.nativeElement.style.cursor = "default"
  }


  //---------------------------------------------------------------MAPA------------------------------------------------------------------
  /*dispararMapa(message) {
    let separarFrase = message.split("(");
    let frase = separarFrase[0];
    if (frase === '¿Esta es tu ubicación con respecto al municipio donde te encuentras') {
      //this.getCurrentLocation(this.message.codUser);
      this.mapa = true;
    } else {
      this.mapa = false;
    }
  }

  limpiarMapa() {
    var container = L.DomUtil.get('map');
    if (container != null) {
      container.outerHTML = "";
    }
  }

  getCurrentLocation(codUser) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {

        let coordenadas = [position.coords.latitude, position.coords.longitude];

        //guardar cooredenadas
        this._dfs.saveCoordinates(coordenadas, codUser, this.distance).subscribe(
          res => {


            //Configuración inicial de leaflet
            let mymap = L.map('map').setView(coordenadas, 16);

            L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWF0ZW9nYXJjaWFsb3BleiIsImEiOiJja2w0b2JjY3cwdWpyMzJucjB2eGtqeGgyIn0.P1krDgOx17o2CvwOYCTzPA', {
              maxZoom: 18,
              id: 'mapbox/streets-v11',
              tileSize: 512,
              zoomOffset: -1,
              accessToken: 'your.mapbox.access.token'
            }).addTo(mymap);

            //posicion actual
            let marker = L.marker(coordenadas).addTo(mymap);
            var cadenaNodo = `` + `<span>Posición Actual</span>`;
            marker.bindTooltip(cadenaNodo).openTooltip();
            marker.setIcon(redIcon);

            //nodos
            this.makeCapitalMarkers(mymap, this.distance, coordenadas);

            //Circulo
            var circle = L.circle(coordenadas, {
              color: 'red',
              fillColor: '#f03',
              fillOpacity: 0.5,
              radius: 100
            }).addTo(mymap);
          },
          err => {
            //console.log(err);
          }
        );
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }


  makeCapitalMarkers(map, distance, coordenates) {
    let coordenadas = [coordenates[1], coordenates[0]];
    this._dfs.makeCapitalMarkers(distance, coordenadas).subscribe(
      res => {
        //console.log('res', res);
        for (const c of res) {
          const lon = c.latitud;
          const lat = c.longitud;
          const marker = L.marker([lat, lon]).addTo(map); //puntos de atención
          marker.setIcon(greenIcon);
          var cadenaNodo =
            `` +
            `<span>Nodo</span>`;
          marker.bindTooltip(cadenaNodo).openTooltip();

        }
      },
      error => {
        //console.log(error);
      }
    );
  }

  makeCapitalPopup(data: any): string {
    var cadena = `` +
      `<h5><center>Punto de atención</center></h5>`
    return cadena
  }

  makeCirclePopup(radio: any): string {
    return `` +
      `<h3>Covertura de busqueda</h3>` +
      `<div><center>Radio: ${radio} metros </center></div>`
  }*/
  //---------------------------------------------------------------MAPA------------------------------------------------------------------

}
